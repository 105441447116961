import State from '@/domain/state/State';
import getters from './store.getters';
import actions from './store.actions';
import mutations from './store.mutations';

const state = new State();

export default {
    state,
    getters,
    actions,
    mutations
};
